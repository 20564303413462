import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {throttle} from "lodash-es";


@Injectable({
  providedIn: 'root'
})
export class SkimlinksService {
  trackingId: string = '';
  throttledInjectCommissionJunction !: Function
  constructor() {
    this.throttledInjectCommissionJunction = throttle(this.injectCommissionJunction, 1500);
  }

  loadSkimScript = (src: string, type= false)=>{
    const url = window.location.host;
    if (type){
      const script = document.createElement('script');
      script.id = 'skimVariableScript'
      script.textContent = src;
      document.body.appendChild(script);
    } else {
      const script = document.createElement('script');
      script.id = 'skimScript'
      script.src = src;
      document.body.appendChild(script);
    }
  }

  injectScriptAndSetId(trackingId: string) {
    const skimLinkScriptUrl = 'https://s.skimresources.com/js/264995X1750382.skimlinks.js';
    const scriptSettings = `var skimlinks_settings = { skimlinks_tracking: '${trackingId}', skimlinks_target: "_blank" };`
    const scriptElement = document.getElementById('skimScript')
    const skimVariableScriptElement = document.getElementById('skimVariableScript')
    if(scriptElement){
      // Remove script element and re-inject the script
      document.body.removeChild(scriptElement);
      if(skimVariableScriptElement) {
        document.body.removeChild(skimVariableScriptElement);
      }
    }
    this.loadSkimScript(scriptSettings, true)
    this.loadSkimScript(skimLinkScriptUrl, false)
  }

  injectAwin() {
    const awinUrl = `https://www.dwin2.com/pub.1616858.min.js`;
    const scriptElement  = document.getElementById('awin')
    if(scriptElement) {
      document.body.removeChild(scriptElement);
    }
    const script = document.createElement('script');
    script.src = awinUrl;
    script.id = 'awin'
    document.body.appendChild(script);
  }

  injectCommissionJunction(trackingId?: string) {
      const commissionJunctionScriptUrl = `${environment.apiUrl}/showroom/modify-script?url=https://www.anrdoezrs.net/am/101141571/include/joined/generate/onLoad/sid/${trackingId ? trackingId: this.trackingId}/debug/false/am.js`;
      const scriptElement  = document.getElementById('commission-junction')
    if(scriptElement) {
      document.body.removeChild(scriptElement);
    }
      const script = document.createElement('script');
      script.src = commissionJunctionScriptUrl;
      script.id = 'commission-junction';
      document.body.appendChild(script);
  }

  loadAffliateLinks(trackingId: string) {
    this.trackingId = trackingId;
    if(environment.isPreview){ return;}
      // this.injectScriptAndSetId(trackingId);
      this.injectCommissionJunction(trackingId);
      // this.injectAwin();
  }
}
